<template>
  <div>
    <vs-col>
      <vs-row vs-type="flex" vs-w="3" vs-sm="3" vs-xs="12">
        <h1 class="primary font-bold" style="color: #555555">Events</h1>
      </vs-row>

      <div class="mt-6">
        <div class="iframe-container">
          <iframe
            src="https://fresh-events.vercel.app/"
            style="border: 1px solid #bfcbda88; border-radius: 4px; width: 100%; background-color: white;"
            frameborder="0"
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
            allowtransparency="true"
          ></iframe>

        </div>
      </div>
    </vs-col>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import inviteUser from "../components/others/inviteUser";
import moment from "moment";
import { CalendarIcon, MapPinIcon } from "vue-feather-icons";

export default {
  components: {
    inviteUser,
    CalendarIcon,
    MapPinIcon,
  },
  data() {
    return {
      events: [],
    };
  },
  methods: {
    ...mapActions("basic", ["fetchAllEvents"]),
    momentDate(date) {
      return moment(date).format("ddd, MMM YYYY");
    },
    momentWeekday(date) {
      return moment(date).format("ddd");
    },
    momentDay(date) {
      return moment(date).format("DD");
    },
    momentTime(start, end) {
      return (
        moment(start).format("HH:mm a") + " to " + moment(end).format("HH:mm a")
      );
    },
    getImage(logo) {
      return logo.replace(
        process.env.VUE_APP_BASE_API_URL + "/api/generate-url/",
        "https://img.evbuc.com/"
      );
    },
    goTo(url) {
      console.log("url", url);
      window.open(url, "_blank");
    },
    getData() {
      this.$vs.loading();
      this.fetchAllEvents("test")
        .then((res) => {
          this.events = res.data.data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
        });
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style scoped>
.heading {
  font-size: large;
  font-weight: 600;
  color: #000;
}
.count {
  font-size: x-large;
  font-weight: 700;
  text-align: center;
}

/* Responsive iframe container */
.iframe-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 aspect ratio (height: width) */
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
